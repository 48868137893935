import { useState, useCallback } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useAsyncDebounce} from 'react-table'

export const LevelFilter = ({level, setLevel, onChange}) => {
  const defaultClass = "radio text-center bg-opacity-50 self-center py-2 px-4 cursor-pointer inline-flex radio text-center self-center"
  const activeClass = `bg-opacity-100 text-white ${defaultClass}`
  const handleChange = (value) => {
    setLevel(value)
    onChange()
  }
  return (
    <RadioGroup value={level} onChange={handleChange} className="flex rounded-md my-1">
      <RadioGroup.Option value="all" >
        {({ checked }) => (
          <span className={`${checked ? activeClass : defaultClass} rounded-l-md bg-green-400`}>All</span>
        )}
      </RadioGroup.Option>
      <RadioGroup.Option value="warning" >
        {({ checked }) => (
          <span className={`${checked ? activeClass : defaultClass} bg-orange-400`}>Low</span>
        )}
      </RadioGroup.Option>
      <RadioGroup.Option value="critical" >
        {({ checked }) => (
          <span className={`${checked ? activeClass : defaultClass} rounded-r-md bg-red-400`}>Empty</span>
        )}
      </RadioGroup.Option>
    </RadioGroup>
  )
}
export const PropertyGlobalFilterHeader = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length
  const [search, setSearch] = useState()
  const [level, setLevel] = useState("all")
  const onChange = useAsyncDebounce(()=> {
    setGlobalFilter( {search, level})
  }, 200)

  return (
    <>
    <div className="px-4 flex flex-1 text-right">
      <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">Search: </span>
        <input
          type="text"
          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={search || ""}
          onChange={e => {
            setSearch(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} properties...`}
        />
      </label>
    </div>
    <LevelFilter {...{level, setLevel, onChange}}/>
    </>
  )
}

const checkMinLevel = (stat, filter) => {
  return (filter === 'all') || (filter === stat) || (filter==='warning' && stat ==='critical')
}

export const PropertyGlobalFilter = (rows, columns, filterValue) => {
  return rows.filter(row => {
    const rowValue = row.values.address;
    return rowValue !== undefined
      ? String(rowValue)
          .toLowerCase()
          .includes(String(filterValue.search || "")
          .toLowerCase()
          ) && checkMinLevel(row.original.status, filterValue.level)
      : true;
  })
}
