import { useEffect, useState, useRef } from "react";
import { components } from "react-select";
import PlacesAutocomplete  from "react-google-places-autocomplete";

const Input = (props) => <components.Input {...props} isHidden={false} />;
const FormikPlacesAutoComplete = (props) => {
  const selectRef = useRef()
  const [inputValue, setInputValue] = useState(props.field.value)
  useEffect(() => { 
    setInputValue(props.field.value)
    setValue(props.field.value)
    }, [props.field.value])
  const [value, setValue] = useState(props.field.value)
  const handleError = error => {
    props.form.setFieldError(props.field.name, error);
  };

  const handleChange = address => {
    props.form.setFieldValue("gmaps_place_id", address?.value?.place_id)
    props.form.setFieldTouched(`${props.field.name}.value`);
    let val = address?.label?.replace(/, USA$/,'')
    props.form.setFieldValue(props.field.name, val)
    setValue(val)
    setInputValue(val)
  }
  const onInputChange = (text, { action, ...options }) => {
    if (action === "input-blur") {
      setInputValue(value)
    }
    if (action === 'input-change')
      setInputValue(text)
  }
  const onFocus = () => {
    selectRef.current.select.select.inputRef.select();
  }
 
  const {
    field: { name, ...field }, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    classes,
    label,
    ...extra_props
  } = props;
  const error = errors[name];
  const touch = touched[name];
  const NoOptionsMessage = props => {
      return (
        <components.NoOptionsMessage {...props}>
          <span>Address not found</span>
        </components.NoOptionsMessage>
      );
  };
  return (
      <PlacesAutocomplete
        apiKey={process.env.REACT_APP_MAPS_API_KEY}
        name={name}
        id={name}
        {...props}
        selectProps={{
          ref: selectRef,
          placeholder: "Find a location..",
          value:  [{ label: props.form.values[props.field.name], value: { place_id: props.form.values.gmaps_place_id, reference: props.form.values.gmaps_place_id }}],
          openMenuOnClick: false,
          controlShouldRenderValue: false,
          isClearable: true,
          inputValue: inputValue,
          components:{
              Input,
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              NoOptionsMessage: NoOptionsMessage
          },
          styles: {
                input: (provided) => ({
                  ...provided,
                  width: '100%',
                  '& input:focus': {
                    boxShadow: `0 0 0 0px`,
                  }
                }),
          },
          onChange: handleChange,
          onInputChange: onInputChange,
          onFocus: onFocus,
          onError: handleError,
        }}
        autocompletionRequest={{componentRestrictions: {country: 'us'} , types: ['address']}}
      />
    );
}

export default FormikPlacesAutoComplete;
