import React from 'react'
import { Navigate, Outlet,  } from 'react-router-dom'
import AuthService from 'core/state/AuthService'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = AuthService.isLoggedIn()
  return isLoggedIn ? <Outlet /> :  <Navigate to="/auth/login" />
}

export default PrivateRoute
