import { Routes, Route } from "react-router-dom";
import { Page404 } from 'components/Common/Errors'


export const ManagedRoutes = ({children}) => {
  return (
    <Routes>
      {children}
      <Route path="*" element={<Page404 />}/>
    </Routes>
  );
}
