import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { middleWare, apiReducer,  combineConfigs } from 'redux-rails'
import { persistStore, persistReducer } from 'redux-persist'
import { apiMiddleware } from 'core/common/ApiMiddlware'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import AuthService from './AuthService'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [ 'Auth', 'Contact' ]
}


const defaultApiConfig = (store = {}) => {
  return {
    baseUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000/api/v1/',
    fetchParams: {
      headers: {
        'Content-Type':'application/json',
        'Authorization': AuthService.getToken(),
      }
    },
    resources: {
      Customers: {
        controller: 'customers'
      },    
      Fills: {
        controller: 'fills'
      },
      Tanks: {
        controller: 'tanks'
      },
      Properties: {
        controller: 'properties'
      },
      User: {
        controller: 'user',
        optimisticUpdateEnabled: false,
      },
      Contact: {
        controller: 'contact',
        optimisticUpdateEnabled: false,
      },
      Auth: {
        controller: 'auth',
        optimisticUpdateEnabled: false,
      }
    }
  }
}

const rootReducer = apiReducer(defaultApiConfig)
const persistedReducer = persistReducer(persistConfig, rootReducer)



export const store = createStore(persistedReducer,
      {},
      compose(
        applyMiddleware(middleWare(defaultApiConfig),apiMiddleware)
      )
    )
export const  Persistor = persistStore(store)
