import { toast } from 'react-toastify'

export const apiMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type.endsWith('_ERROR')) {
    if (action?.error === 'Invalid Token') {
      toast.warning('Invalid credentials, please login again')
    } else {
      if(typeof action?.error === 'object') 
        if(action?.error?.message)
          toast.warning(action.error.message)
        //if(Array.isArray(action.error))
        else
          Object.entries(action?.error).map(([name, message]) => toast.warning(`${name}: ${message}`) )   
      else
        toast.warning(action?.error)
    }
  }
  next(action);
};
