import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapPin, faMapMarkerAlt, faSearchLocation } from '@fortawesome/free-solid-svg-icons'


const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: ${(props) => (props.color || '#000')};
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;


const InfoWindow = (props) => {
  const { address, level, id } = props;
  const infoWindowStyle = {
    bottom: 100,
  };
  return (
    <div className="-left-32 z-50 w-64 p-2 shadow justify-around flex rounded relative bg-white" style={infoWindowStyle}>
      <div className="text-xs text-center p-1">{level}%</div>
      <div className="text-xs text-center p-1">{address}</div>
      <Link to={`/dashboard/properties/${id}`}>
        <FontAwesomeIcon className="float-right -top-2"  icon={faSearchLocation} size="2x" />
      </Link>
    </div>
  )
}
const Marker = ({  onClick, color, showInfo, ...props }) => {
  return (<>
  <span className="cursor-pointer fa-layers fa-fw relative -top-2 -left-1">
  <FontAwesomeIcon className={`${color}`} icon={faMapPin} size="2x" />
  </span>
  {showInfo && <InfoWindow {...props} />}
  </>)
};

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
};

export default Marker;
