import {AddressCell, ActionCell} from '../Property/TableRow'
import {TextCell} from '../Common/TableCells'

const Columns = ({editObject}) =>  (
  [
    {
      Header: 'Name',
      colSpan: 2,
      Cell: TextCell,
      disableFilters: true,
      headerClassName: "px-6",
      accessor: 'name',
    },
    {
      Header: ' ',
      Cell: (props) => <ActionCell editAction={editObject} {...props} />,
    },
  ])

export default Columns
