import React, {useState, useEffect} from "react";
import { useDispatch } from 'react-redux';
import { railsActions } from 'redux-rails'
import { useNavigate,  Link } from "react-router-dom"
import AuthService from 'core/state/AuthService'
import { Formik, Form, Field } from 'formik';
import FormError from 'components/Alerts/FormError'
import * as Yup from 'yup';

const Login = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [actions, setActions] = useState(0);

  const initialValues = {
    email: "",
    password: "",
  }
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
     .min(2, 'too short')
     .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  })
  const login =  (values) => dispatch(railsActions.create({ 
      resource: 'Auth', 
      attributes: {user: values}
    })
  ).then(response => {
      AuthService.logIn(response?.response?.jwt, values.remember)
  })
  const handleSubmit = (values, actions) => {
    login(values).then(() => {
      setActions(v => v+1)
    }).finally(() =>  actions.setSubmitting(false))
  }
  useEffect(() => {
    if (AuthService.isLoggedIn())
      navigate("/dashboard", {replace: true});
  }, [navigate, actions]);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Formik validationSchema={LoginSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                  {({ isValidating, isSubmitting }) => (
                    <Form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2 mt-3"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <Field
                          name="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
                          placeholder="Email"
                          autoComplete="username"
                        />
                        <FormError name="email" />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                          autoComplete="current-password"
                        />
                        <FormError name="password" />
                      </div>
                      <div>
                        <label className="inline-flex items-center cursor-pointer">
                          <Field
                            id="customCheckLogin"
                            name="remember"
                            type="checkbox"
                            className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                          />
                          <span className="ml-2 text-sm font-semibold text-blueGray-600">
                            Remember me
                          </span>
                        </label>
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Sign In
                        </button>
                      </div>
                    </Form>
                  )}
                  </Formik>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <button
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </button>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
