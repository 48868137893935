import React, {useState, useEffect} from "react";
import { railsActions } from 'redux-rails'
import { Formik, Form, Field } from 'formik';
import FormError from 'components/Alerts/FormError'
import AuthService from 'core/state/AuthService'
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom"

const Register = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [actions, setActions] = useState(0);
  
  useEffect(() => {
    if (AuthService.isLoggedIn())
      navigate("/dashboard", {replace: true});
  }, [navigate, actions]);

  const initialValues = {
    name: "",
    email: "",
    password: "",
    agree: false
  }
  const register =  (values) => dispatch(railsActions.create({ 
      resource: 'User', 
      attributes: {user: values}
    })
  ).then(response => {
      AuthService.logIn(response?.response?.jwt)
  })
  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
     .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
     ).required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    name: Yup.string().min(2, 'too short').required('Required'),
    agree: Yup.boolean().oneOf([true], 'You must agree to our privacy policy')
  })
  const handleSubmit = (values, actions) => {
    register(values).then(() => {
      setActions(v => v+1)
    }).finally(() =>  actions.setSubmitting(false))
  }
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Formik validationSchema={RegisterSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                  {({ isValidating, isSubmitting }) => (
                    <Form>
                      <div className="relative w-full mb-3 mt-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Name
                        </label>
                        <Field
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Name"
                          name="name"
                        />
                        <FormError name="name" />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <Field
                          type="email"
                          name="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                        />
                        <FormError name="email" />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                        />
                        <FormError name="password" />
                      </div>

                      <div>
                        <label className="inline-flex items-center cursor-pointer">
                          <Field
                            id="customCheckLogin"
                            type="checkbox"
                            name="agree"
                            className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                          />
                          <span className="ml-2 text-sm font-semibold text-blueGray-600">
                            I agree with the{" "}
                            <button
                              href="#"
                              className="text-lightBlue-500"
                              onClick={(e) => e.preventDefault()}
                            >
                              Privacy Policy
                            </button>
                          </span>
                        </label>
                        <FormError name="agree" />
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Create Account
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register

