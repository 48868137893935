import React from "react";
import {  Route } from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

import { ManagedRoutes } from 'core/routing/ManagedRoutes'

// views

import Login from "views/auth/Login.js";
import Logout from "views/auth/Logout.js";
import Register from "views/auth/Register.js";

export default function Auth() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full "
          ></div>
          <ManagedRoutes>
            <Route path="login" exact element={<Login />} />
            <Route path="logout" exact element={<Logout />} />
            <Route path="register" exact element={<Register />} />
          </ManagedRoutes>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
