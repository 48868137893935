
import { Formik, Field , useField} from 'formik';
const EditTanks = (props) => {
  const [field, tanks, helpers] = useField(props);
  return <>
    {tanks.value.map((tank, index) => (
              <div key={index} className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-size"
                    >
                      size
                    </label>
                    <Field
                      type="hidden"
                      name={`tanks[${index}].id`}
                      className="disabled:bg-slate-200 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 text-right"
                    />
                    <Field
                      type="number"
                      name={`tanks[${index}].size`}
                      className="disabled:bg-slate-200 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 text-right"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-quanity"
                    >
                      Quantity
                    </label>
                    <Field
                      type="number"
                      name={`tanks[${index}].quantity`}
                      className="disabled:bg-slate-200 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 text-right"
                    />
                  </div>
                </div>
            </div>
    ))}
    </>
}
export default EditTanks
