import { useNavigate } from "react-router-dom"
import AuthService from 'core/state/AuthService'
import { useEffect } from 'react'

const Logout = (props) => {

  const navigate = useNavigate();
  AuthService.logOut()
  useEffect(() => {
    AuthService.logOut()
    navigate("/auth/login", {replace: true});
  }, [navigate]);
  return <></>
}

export default Logout
