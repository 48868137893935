import { useEffect} from "react";
import { railsActions } from 'redux-rails'
import { useDispatch } from 'react-redux'
import Table from './Table'

const TableList = ({table, resource, ...props}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(railsActions.index({ resource: resource}))
  }, [dispatch, resource])

  return (
    <div className="flex flex-wrap md:mt-4 mt-0 min-h-screen">
      <div className="w-full mb-12 md:px-4">
        <Table table={table} resource={resource}/>
      </div>
    </div>
  )
}

export default TableList
