import React, {useCallback, useEffect} from "react";
import { Formik, Form, Field } from 'formik';
import FormError from 'components/Alerts/FormError'
import * as Yup from 'yup';
import { railsActions } from 'redux-rails'
import { toast } from 'react-toastify'

import { useSelector, useDispatch } from 'react-redux'



export const CardSettings  = (props) => {

  const user = useSelector((state) => state.User?.attributes?.user)

  const dispatch = useDispatch()

  const updateUser = useCallback(
    (values) => dispatch(railsActions.update({
      resource: 'User',
      attributes: {user:values}
      })),
    [dispatch]
  )

  const handleSubmit = (values, actions) => {
    updateUser(values).then(huh => {
    }).catch(res => {
      actions.setSubmitting(false)
    })
  }

  return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">My account</h6>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <Formik enableReinitialize validationSchema={false} initialValues={user} onSubmit={handleSubmit}>
              {({ isValidating, isSubmitting }) => (
                <Form>
                  <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                    User Information
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Email address
                        </label>
                        <Field
                          type="email"
                          name="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                        <FormError name="email" />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Name
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                        <FormError name="name" />
                      </div>
                    </div>
                  </div>


                  <div className="text-center float-right flex justify-between">
                    <button
                      className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
}
export default CardSettings
