import { ErrorMessage } from 'formik';

function FormError(props) {
  return (
    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
      <ErrorMessage {...props} />
    </span>
  )
}

export default FormError
