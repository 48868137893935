import PropTypes from "prop-types";
import { Field } from 'formik';
import FormError from 'components/Alerts/FormError'

const widthStyles = {
  "1": "lg:w-1/12",
  "2": "lg:w-2/12 w-4/12",
  "3": "lg:w-3/12",
  "4": "lg:w-4/12 w-8/12",
  "6": "lg:w-6/12",
  "8": "lg:w-8/12",
  "12": "lg:w-12/12",
}
export const FormField = ({label, width, attribute, ...props}) => {
  return (
                <div className={`w-full ${widthStyles[width]} px-4`}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      {label}
                    </label>
                    <Field 
                      name={attribute}
                      {...props}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <FormError name={attribute} />
                  </div>
                </div>
  )
}
FormField.defaultProps = {
  width: 12,
};

FormField.propTypes = {
  attribute: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number
};

