import { useState,useEffect, useCallback } from "react";
import { Formik,FieldArray, Form, Field } from 'formik';
import FormError from 'components/Alerts/FormError'
import FormikPlacesAutoComplete from 'components/Forms/FormikPlacesAutoComplete'
import * as Yup from 'yup';
import EditTanks from './EditTank'
import CustomerSelect from 'components/Customer/Select'
import CrudActions from 'core/state/crud_actions'

const Edit = (props) => {

  const { archive: archiveProperty, destroy: deleteProperty,  get: getProperty , update: updateProperty , create: createProperty } = CrudActions('Properties')
  const [property, setProperty] = useState(props.object || {temperature: 65, tanks: [ { size: 275, quantity: 1 }]})
  
   useEffect(() => 
    {
      if (props.object_id) 
      getProperty(props.object_id).then((x) =>
        setProperty(x.response)
      )
    }, [props.object_id])
  
  const newProperty = !property.id
  if(!(property.tanks?.length>0)) {
    
    property.tanks = [{
      size: 275,
      quantity: 1,
    }]
  }
  const destroy = () =>  deleteProperty(property.id).then(() => 
      props.hideAction() 
  )
  const archive = () =>  archiveProperty(property.id, !property.archived).then(() => 
      props.hideAction() 
  )
  const save = (values,action) => {
    values.tanks_attributes = values.tanks
    var method
    if(newProperty)
      method = createProperty
    else
      method = updateProperty
    method(values).then(() => 
      props.hideAction()
    )
  }
  

  const cancel = () => {
    props.hideAction()
  }
  const PropertySchema = Yup.object()
  return (
    <>
      <div
        className="justify-center items-center overflow-x-hidden overflow-y-auto fixed top-0 left-0 right-0 z-50 outline-none focus:outline-none backdrop-blur inset-0"
      >
        <div className="relative w-auto my-6 mx-auto max-w-xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
    <div className="relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <Formik enableReinitialize validationSchema={PropertySchema} initialValues={property} onSubmit={save}>
          {({ isValidating, isSubmitting }) => (
            <Form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Property Information
              </h6>
              <button
                className="absolute top-1 right-1 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 text-right fa fa-times"
                type="button"
                onClick={cancel}
              >
                
              </button>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Customer
                    </label>
                    <Field component={CustomerSelect}
                      name="customer_id"
                      autocomplete="off"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <FormError name="customer_id" />
                  </div>
                </div>
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Address
                    </label>
                    <Field component={FormikPlacesAutoComplete}
                      name="address"
                      autocomplete="off"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <FormError name="address" />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                Property Details
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-sqf"
                    >
                      Square Feet
                    </label>
                    <Field
                      type="text"
                      name="sqft"
                      className="disabled:bg-slate-200 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 text-right"
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-last_fill"
                    >
                      Last Delivery
                    </label>
                    <Field
                      type="text"
                      name="last_fill"
                      disabled
                      className="disabled:bg-slate-200 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 text-right"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-kfactor"
                    >
                      K-Factor
                    </label>
                    <Field
                      type="text"
                      name="kfactor"
                      disabled
                      className="disabled:bg-slate-200 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 text-right"
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-6 border-b-1 mb-2 border-blueGray-300" />
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-temperature"
                    >
                      Temperature (Fº)
                    </label>
                    <Field
                      type="number"
                      name="temperature"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3 form-check form-switch">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-kfactor"
                    >
                      Hot water
                    </label>
                    <label htmlFor="water-toggle" className="flex relative items-center mb-4 cursor-pointer">
                      <Field
                        id="water-toggle"
                        type="checkbox"
                        name="water"
                        className="sr-only"
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full border border-gray-400 toggle-bg"></div>
                    </label>
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 mb-2 border-blueGray-300" />
              <h6 className="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                Tanks
              </h6>
              <FieldArray 
                name="tanks"
                component={EditTanks} />

              { newProperty ? "" : 
              <div className="text-center float-left flex justify-around">
                <button
                  className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={destroy}
                  name="delete"
                >
                  Delete
                </button>
                <button
                  className="bg-green-400 text-white active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  name="archive"
                  onClick={archive}
                >
                 { property.archived ? "Unarchive" : "Archive" }
                </button>
              </div> }
              <div className="text-center float-right flex justify-around">
                <button
                  className="bg-slate-400 text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  name="cancel"
                  onClick={cancel}
                >
                  {newProperty ? "Cancel" : "Close"}
                </button>
                <button
                  name="save"
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Save
                </button>
              </div>

            </Form>
          )}
        </Formik>
      </div>
    </div>
          </div>
        </div>
      </div>
      </>
  );
}

export default Edit
