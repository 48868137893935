import React from "react";
import {  Link , useMatch, useResolvedPath} from "react-router-dom";

export default function SidebarLink({onClick, ...props}) {
  let resolved = useResolvedPath(props.path);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
              <li className="items-center">
                <Link onClick={onClick}
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (match
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to={props.path}
                >
                  <i
                    className={
                      `fas fa-${props.icon} mr-2 text-sm fa-fw ` +
                      (match
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  {props.name} 
                </Link>
              </li>
  )
}
