/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import PageViewTracking from 'core/common/PageViewTracking'

// components

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <PageViewTracking />
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between w-auto lg:static block justify-start">
            <Link
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              to="/"
            >
              Flowssil
            </Link>
          </div>
          <div
            className={"flex flex-grow items-center bg-white bg-opacity-0 shadow-none"}
            id="navbar-warning"
          >
            <ul className="flex flex-col flex-row list-none ml-auto">
              <li className="flex items-center">
                <Link to="/auth/login">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
                  <i className="fas fa-id-badge"></i> Login
                </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
