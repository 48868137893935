class AuthService {
  key = "TOKEN"
  logIn = (jwt, remember = false) => {
    sessionStorage.setItem(this.key, jwt)
    if(remember) 
      localStorage.setItem(this.key, jwt)
  }
  logOut = ()  => {
    localStorage.removeItem(this.key)
  }
  isLoggedIn = event => {
    return !!this.getToken()
  }
  getToken = () => {
    if(sessionStorage.getItem(this.key))
      return sessionStorage.getItem(this.key);
    if(localStorage.getItem(this.key)) {
      sessionStorage.setItem(this.key, localStorage.getItem(this.key))
      return this.getToken()
    }
  }
}

export default new AuthService()
