import {  useLocation } from 'react-router-dom';
import { useEffect} from 'react'
import ReactGA from "react-ga4"

const PageViewTracking = (props) => {
  const { pathname }  = useLocation();
  useEffect(() => { 
    ReactGA.pageview(pathname)
  })
  return (
    <>
    </>
  )
}
export default PageViewTracking
