import { railsActions } from 'redux-rails'
import { useState, useEffect } from 'react'
import { store } from './store'
const CrudActions = (model) => {
  const list =  (id) => store.dispatch(railsActions.index({ 
                              resource: model,
  }))

  const get =  (id) => store.dispatch(railsActions.show({ 
                              id: id,
                              resource: model,
  }))

  const destroy =  (id) => store.dispatch(railsActions.destroy({ 
                              id: id,
                              resource: model,
  }))
  
  const update =  ({id, ...values}) => store.dispatch(railsActions.update({ 
                              id: id,
                              resource: model,
                              attributes: {[model.toLowerCase()]: values}
  }))
  
  const archive =  (id, archived = true) => store.dispatch(railsActions.update({ 
                              id: id,
                              resource: model,
                              attributes: {[model.toLowerCase()]: {archived: archived}}
  }))

  const create =  (values) => store.dispatch(railsActions.create({ 
                              resource: model,
                              attributes: {[model.toLowerCase()]: values}
  }))

  return {get, update, create, destroy, list, archive}
}

export const useProperty = (propertyId) => {
  const [propertyData, setPropertyData] = useState();
  useEffect(() => 
    CrudActions('Properties').get(propertyId).then((res) =>
      setPropertyData(res.response))
  , [propertyId]);

  return propertyData;
};

export const useUser = () => {
  const [userData, setUserData] = useState();
  useEffect(() => 
    CrudActions('User').get().then((res) =>
      setUserData(res.response))
  , []);

  return userData;
};


export default CrudActions;
