import React from "react";
import { Link } from "react-router-dom";

// components

export function AddressCell({value, row}) {
  let color="emerald"
  switch(row.original.status) {
    case "critical":
      color="from-red-400"
      break;
    case "warning":
      color="from-orange-400"
      break;
    default:
      color="from-emerald-400"
  }
  return ( 
                    <Link to={`/dashboard/properties/${row.original.id}`} 
                    className={`hover:bg-gray-300 bg-no-repeat bg-gradient-to-r ${color} flex ml-3 font-bold text-slate-800 basis-2/12 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left items-center`} 
                    style={{ backgroundSize: "calc("+row.original.level+"% + 1em)" }}
                  >
                      <div className="flex flex-1">
                        {value}
                      </div>
                      <div className="flex flex-right text-xs h-2 justify-center">
                        {row.original.level}%
                      </div>
                    </Link>
  )
}
export function LevelCell({value, row}) {
  let color="emerald"
  switch(row.original.status) {
    case "critical":
      color="red"
      break;
    case "warning":
      color="orange"
      break;
    default:
      color="emerald"
  }
  return (
                  <div className="flex items-center">
                    <span className="mr-2">{value}%</span>
                    <div className="relative w-full">
                      <div className={`overflow-hidden h-2 text-xs flex rounded bg-${color}-200`}>
                        <div
                          style={{ width: value+"%" }}
                          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${color}-500`}
                        ></div>
                      </div>
                    </div>
                  </div>
  )

}
export function ActionCell({value, row, editAction, ...props}) {
  return (
  <div className="flex auto">
    <div className="m-auto">
    <button className="text-sky-700 fas fa-edit fa-1x"
      onClick={() => editAction(row.original)}
    />
    </div>
    {/*
    <button className="fas fa-truck-loading text-lg"
      onClick={() => {} }
    />*/}
  </div>
  )
}
