import Select from 'react-select'
import {  useMemo, useState, useEffect} from 'react'
import { useSelector} from 'react-redux'
const CustomerSelect = (props) =>  {
  useEffect(() => { 
    setValue(props.field.value)
    }, [props.field.value])
  const [value, setValue] = useState(props.field.value)

  const createNewCustomer = useMemo(() => ({value: -1, label: "Create new customer..."}),[])
  const rows = useSelector((state) => state['Customers']?.models)
  const data = useMemo(() => [createNewCustomer].concat(rows.map(p => ({ value: p.id, label: p.attributes.name }))), [rows, createNewCustomer])
  const selected = useMemo(() => data.find(p => p.value === props.field.value) || createNewCustomer, [data, props.field.value, createNewCustomer])
  const handleChange = val => {
    props.form.setFieldTouched(`${props.field.name}.value`);
    props.form.setFieldValue(props.field.name, val.value)
    setValue(value)
  }
  
  return (
    <Select options={data} 
          isClearable={false}
          //getOptionValue={ x => x.value}
          value={selected}
          onChange={handleChange}
          styles= {{
                input: (provided) => ({
                  ...provided,
                  width: '100%',
                  '& input:focus': {
                    boxShadow: `0 0 0 0px`,
                  }
                }),
          }}
    />
  )
}

export default CustomerSelect
