import Chart from "chart.js/auto"
import {useMemo, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import { useProperty } from 'core/state/crud_actions'
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-moment'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    line: {
      tension: .5
    }
  },
  scales: {
     yGallons: {
        position: 'left',
        type: 'linear',
        label: 'gallons',
        title: {
          display: true,
          text: 'Gallons used'
        }
      },
      yWeather: {
        position: 'right',
        type: 'linear',
        title: {
          display: true,
          text: 'Outdoor Temperature'
        }
      },
      yCapacity: {
        position: 'left',
        max: 100,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Percentage Remaining'
        }
      },
      xStretch: {
        offset: false,
        type: 'time',
        time: {
          unit: 'day',
          round: true,
          minUnit: 'day',
          tooltipFormat: 'MMM D'
        }

      }
  },
  plugins: {
    filler: { propagate: true},
    legend: {
      labels: {
        filter: item => item.text !== 'Capacity Remaining'
      },
      position: 'bottom',
    },
    title: {
      display: false,
    },
  },
};



const View = (props) => {
  
  const critical = (ctx) => (ctx?.p1?.parsed?.y < 25)  ? 'rgba(248, 113, 113, 0.5)' : undefined
  const low = (ctx) => (ctx?.p1?.parsed?.y < 50)  ? 'rgba(251, 146, 60, 0.5)' : undefined
  const normal = () => 'rgba(110, 231, 183, 0.5)'
  const tankColor = ctx => critical(ctx) || low(ctx) || normal()

  const { id } = useParams();
  const property = useProperty(id);

  const data = {
//  labels,
  datasets: [
    {
      type: 'bar',
      label: 'Gallons used',
      data: property?.gallons_used,
      yAxisID: 'yGallons',
      backgroundColor: 'rgba(87, 83, 78, .5)'
    },
    {
      type: 'line',
      label: 'Outside Temperature',
      data: property?.weather,
      yAxisID: 'yWeather',
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      type: 'line',
      label: 'Capacity Remaining',
      data: property?.capacity_left,
      yAxisID: 'yCapacity',
      fill: true,
      segment: {
        backgroundColor: tankColor,
        borderColor: tankColor
      },

    },
  ],
};
  return <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Fuel usage
              </h6>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          <div className="relative ">
          <Bar
          options={options}
          data={data}
          {...props}
        />
          </div>
        </div>
      </div>
}

export default View
