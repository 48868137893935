import  {useState} from "react";
import { Formik,FieldArray, Form, Field } from 'formik';
import FormError from 'components/Alerts/FormError'
import * as Yup from 'yup';
import { FormField } from 'components/Common/FormField'
import EditProperty from 'components/Property/Edit'
import CrudActions from 'core/state/crud_actions'

const Edit = (props) => {
  

  const [targetProperty, setTargetProperty] = useState(false)
  const object = props.object || {}
  
  const newObject = !object.id
  // cast(value, { stripUnknown: true})
  // const v = contactSchema.noUnknown().cast({ })
  const save = (values,action) => {
    values.tanks_attributes = values.tanks
    var method
    if(newObject)
      method = createCustomer
    else
      method = updateCustomer
    method(values).then(() => 
      props.hideAction()
    )
  }
  
  const { archive: archiveCustomer, destroy: deleteCustomer, get: getCustomer , update: updateCustomer , create: createCustomer } = CrudActions('Customers')
  
  const cancel = () => {
    props.hideAction()
  }
  
  const destroy = () =>  deleteCustomer(object.id).then(() => 
      props.hideAction() 
  )
  const archive = () =>  archiveCustomer(object.id, !object.archived).then(() => 
      props.hideAction() 
  )
  const ObjectSchema = Yup.object()
  return (
    <>
      <div
        className="justify-center items-center overflow-x-hidden overflow-y-auto fixed top-0 left-0 right-0 z-50 outline-none focus:outline-none backdrop-blur inset-0"
      >
        <div className="relative w-auto my-6 mx-auto max-w-xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
    <div className="relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <Formik enableReinitialize validationSchema={ObjectSchema} initialValues={object} onSubmit={save}>
          {({ isValidating, isSubmitting }) => (
            <Form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Customer Information
              </h6>
              <button
                className="absolute top-1 right-1 outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 text-right fa fa-times"
                type="button"
                onClick={cancel}
              >
                
              </button>
              <div className="flex flex-wrap">
                <FormField label="First Name" attribute="first_name" width={6} />
                <FormField label="Last Name" attribute="last_name" width={6} />
              </div>
              <div className="flex flex-wrap">
                <FormField label="Business Name" attribute="business_name" width={12} />
              </div>
              <div className="flex flex-wrap">
                <FormField label="Email" attribute="email" width={6} />
                <FormField label="Phone" attribute="phone" width={6} />
              </div>
              <div className="flex flex-wrap">
                <FormField label="Address" attribute="address" width={12} />
              </div>
              <div className="flex flex-wrap">
                <FormField label="City" attribute="city" width={6} />
                <FormField label="State" attribute="state" width={2} />
                <FormField label="Zipcode" attribute="zipcode" width={4} />
              </div>
                
              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                Properties
              </h6>
              <div className="flex flex-wrap">
                {object.properties.map(property => ( 
                  <div key={`edit-property-wrapper-${property.id}`} className="flex py-1 w-full">
                    <div className="w-full lg:w-8/12 text-sm" key={`edit-property-${property.id}`} >{property.address}</div>
                    <button key={`edit-${property.id}`} className="text-sky-700 fas fa-edit fa-1x text-right w-full lg:w-4/12"
                        type="button"
                        onClick={() => setTargetProperty(property)}
                    />
                  </div>
                ))} 
              </div>
              <hr className="mb-3 border-b-1 border-blueGray-300" />
              { newObject ? "" : 
              <div className="text-center float-left flex justify-around">
                <button
                  className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={destroy}
                >
                  Delete
                </button>
                <button
                  className="bg-green-400 text-white active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={archive}
                >
                 { object.archived ? "Unarchive" : "Archive" }
                  
                </button>
              </div> }
              <div className="text-center float-right flex justify-around">
                <button
                  className="bg-slate-400 text-white active:bg-slate-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={cancel}
                >
                  {newObject ? "Cancel" : "Close"}
                </button>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Save
                </button>
              </div>

            </Form>
          )}
        </Formik>
      </div>
    </div>
          </div>
        </div>
      </div>
      {targetProperty ? (<EditProperty object_id={targetProperty.id} hideAction={() => setTargetProperty(false)}/>) : null}
      </>
  );
}

export default Edit
