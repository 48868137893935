import {useCallback , useRef, useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux'
import GoogleMapReact, {  fitBounds } from 'google-map-react';
import { railsActions } from 'redux-rails'
import { toast } from 'react-toastify'
// not yet 17 compat
//import { usePosition } from 'use-position';

//import MapsMarker from './MapsMarker'
import Marker from './Marker'

const mapOptions = {
  zoom: 1,
  scrollwheel: false,
  zoomControl: true,
  styles: [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [{ saturation: -100 }, { lightness: 45 }],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{ color: "#f2f2f2" , visibility: "off"}],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{ saturation: -100 }, { lightness: 45 }],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
  ],
};

const MapContainer = (props) => {
  //const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  //const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0});
  const [rebound, setRebound] = useState(true)
  const dispatch = useDispatch()
  const mapRef = useRef(null)
  const mapsRef = useRef(null)
  /*const {
    latitude,
    longitude,
  } = usePosition(true);*/
  /*
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };*/
  useEffect(() => {
    dispatch(railsActions.index({ resource: 'Properties'}))//.catch(err => toast.error(err.error.message))
  }, [dispatch])
  useEffect(() => {
    //navigator?.geolocation.watchPosition(({coords: {latitude: lat, longitude: lng}}) => {
     //   setCurrentPosition({lat, lng})
    //})
  })
  const properties = useSelector((state) => state.Properties?.models)
  const color = (status) => {
    let color = "green"
    switch(status) {
      case "critical":
        color="text-red-500"
        break;
      case "warning":
        color="text-orange-400"
        break;
      default:
        color="text-emerald-500"
    }
    return color
  }
  const bounds = {
  nw: {
    lat: 50.01038826014866,
    lng: -118.6525866875
  },
  e: {
    lat: 32.698335045970396,
    lng: -92.0217273125
  }
};
  const positions = properties.map(({attributes: {lat, lng }}) => ({lat, lng }))
  const [markers, setMarkers] = useState(properties);
  const getBounds = useCallback(() => {
    if (!mapsRef.current) return
    if (!positions?.length) return
    if (!rebound) return
    let bounds = new mapsRef.current.LatLngBounds();
    positions.forEach((pos) => bounds.extend(pos))   
    mapRef.current.fitBounds(bounds) 
    setRebound(false)
    return bounds
  },[rebound, positions])

  useEffect(() => {
    getBounds()
  }, [positions, getBounds])

  const onChildClickCallback = (key, foo) => {
    setMarkers(prevMarkers => {
      const index = prevMarkers.findIndex((e) => e.id === foo.id);
      if(prevMarkers[index].showInfo) 
        prevMarkers[index].showInfo=false
      else {
        prevMarkers.forEach((m) => m.showInfo = false)
        prevMarkers[index].showInfo=true
      }
      return [...prevMarkers]
    })
  };

  const markerObjs = markers.map(({id, showInfo, attributes: {level, address, status, lat,lng}}) => (
    <Marker color={color(status)} key={id} {...{id,address,level,lat,lng,showInfo}} />))
  return (
    <div className="relative w-full rounded h-600-px">
      <div className="rounded h-full">
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map
            mapsRef.current = maps
            getBounds()
          }}
          bootstrapURLKeys={{ 
            key: process.env.REACT_APP_MAPS_API_KEY,
            // used for autocomplete elsewhere
            libraries:['places']
          }}
          options={mapOptions}
          defaultCenter={bounds.nw}
          defaultZoom={10}
          onChildClick={onChildClickCallback}
        >
          {markerObjs}
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default MapContainer
