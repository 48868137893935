import { useEffect } from "react";
import { Route } from "react-router-dom";
import { railsActions } from 'redux-rails'
import { useSelector, useDispatch } from 'react-redux'
import PrivateRoute from 'core/routing/PrivateRoute'
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Overview from "views/admin/Dashboard";
import Maps from "views/admin/Maps";
import Settings from "views/admin/Settings";
import TableList from 'components/Common/TableList'
import Property from 'components/Property/View'
import { ManagedRoutes } from 'core/routing/ManagedRoutes'
export default function Dashboard() {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(railsActions.show({ resource: 'User'}))
  }, [dispatch])

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-0 md:px-10 mx-auto w-full -m-24">
          <ManagedRoutes>
            <Route path="" element={<PrivateRoute/>}>
              <Route path="/" exact element={<Overview/>} />
              <Route path="maps" exact element={<Maps />} />
              <Route path="settings" exact element={<Settings />} />
              <Route path="properties" exact element={<TableList table="Property" resource="Properties" />} />
              <Route path="properties/:id" exact element={<Property />} />
              <Route path="customers" exact element={<TableList table="Customer" resource="Customers"/>} />
              <Route path="tanks" exact element={<TableList table="Tank" resource="Tanks"/>} />
              <Route path="deliveries" exact element={<TableList table="Fill" resource="Fills"/>} />
            </Route>
          </ManagedRoutes>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
