import { Outlet  } from 'react-router-dom'
// components


export default function TableHead({ name,  color, children , className, ...props}) {
  return (
                <th {...props}
                  className={
                    (className||"") + " text-center align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-sky-800 text-sky-300 border-sky-700")
                  }
                >{children}
                  {name} 
                </th>
  )
}
