import { useEffect } from 'react'

const Tidio = () => {
  useEffect(() => {
    let t="script"
    let d=document
    var BASE_URL="https://chatwoot.enud.com";
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
      window.chatwootSDK.run({
      websiteToken: '5wVWHdudFFg1iq3CgFtv5LE1',
      baseUrl: BASE_URL
      })
    }
  }, []);
  return <></>
}
export default Tidio
