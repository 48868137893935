import {AddressCell,  ActionCell} from './TableRow'
const Columns = ({editObject}) =>  (
  [
    {
      Header: 'Address',
      Cell: AddressCell,
      colSpan: 2,
      disableFilters: true,
      headerClassName: "px-6",
      accessor: 'address',
    },
    {
      Header: 'Level',
      accessor: 'level',
      headerClassName: "hidden md:block px-6",
      className: "hidden",
    },
    {
      Header: ' ',
      Cell: (props) => <ActionCell editAction={editObject} {...props} />,
    },
  ])
export default Columns
