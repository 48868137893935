import {useEffect} from "react"
import {useNavigate} from 'react-router-dom'

export const Page404 = ()  => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/404", {replace: true});
  }, [navigate]);
  return (
        <div className="flex items-center justify-center w-screen h-screen bg-indigo-600">
            <p className="text-5xl text-white md:text-7xl lg:text-9xl">404</p>
        </div>
  )
}
