import React from "react";
import { BrowserRouter, Route, Navigate} from "react-router-dom";
import {Provider} from 'react-redux';
import {store, Persistor } from 'core/state/store'
import { PersistGate } from 'redux-persist/integration/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import fetchIntercept from 'fetch-intercept';
import { ToastContainer} from 'react-toastify';
import { ManagedRoutes } from 'core/routing/ManagedRoutes'
import ReactGA from "react-ga4"

import Chatwoot from 'components/Common/Chatwoot'
import 'react-toastify/dist/ReactToastify.css';
import '@themesberg/flowbite';


// Set up your config




import "@fortawesome/fontawesome-free/css/all.min.css";
import "index.css"

// layouts

import Dashboard from "layouts/Dashboard.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
//import Index from "views/Index.js";

ReactGA.initialize("G-M2Q7EXM33P");

class App extends React.Component {
  state = {
    loading: false
  }
  makeRequest = (url, config) => {
    this.setState({loading: true})
    return [url, config];
  }
  finishRequest = (response) => {
    this.setState({loading: false})
    return response
  }
  failRequest = (error) => {
    this.setState({loading: false})
    return Promise.reject(error)
  }
  componentDidMount () {
    fetchIntercept.register({
          request: this.makeRequest.bind(this),
          response: this.finishRequest.bind(this),
          responseError: this.failRequest.bind(this),
      })
  }
  render() {
    return ( <Provider store={store} >
      <Chatwoot />
      <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      { this.state.loading ? 
        <div className="loader-container flex h-screen" >
          <div className="m-auto">
            <FontAwesomeIcon className="text-slate-800 fa-spin" icon={faCog} size="7x" />
          </div>
        </div> :  null }
      
      <PersistGate
        persistor={Persistor}>
        <BrowserRouter>
          <ManagedRoutes>
            {/* add routes with layouts */}
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/auth/*" element={<Auth />} />
            {/* add routes without layouts */}
            <Route path="/" exact element={<Landing />} />
            {/*<Route path="/profile" exact element={<Profile />} />*/}
      {/* <Route path="/save" exact element={<Index />} /> */}
            {/* add redirect for first page */}
      {/*<Route path="/*" element={<Navigate from="*" to="/" />}/> */}
          </ManagedRoutes>
        </BrowserRouter>
      </PersistGate>
    </Provider>)
  }
}

export default App
