import {useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";


import { useSelector} from 'react-redux'

import { useTable, useFilters, useGlobalFilter,  useSortBy, usePagination } from 'react-table'

import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'


// global components

import { SortIcon, SortUpIcon, SortDownIcon } from './Icons'
import { PageButton } from './Buttons'
import TableHead from './TableHead'

// Properties
import PropertyColumns from 'components/Property/Columns'
import {PropertyGlobalFilterHeader, PropertyGlobalFilter } from 'components/Property/Filters'
import PropertyEdit from 'components/Property/Edit'

//Fills
import FillColumns from 'components/Fill/Columns'

//Customer
import CustomerColumns from 'components/Customer/Columns'
import CustomerEdit from 'components/Customer/Edit'

const TableMap = {
  Property: { ColumnsLookup: PropertyColumns,
              GlobalFilterHeader: PropertyGlobalFilterHeader,
              Actions: {
                Edit: PropertyEdit ,
              },
              GlobalFilter: PropertyGlobalFilter,
            },
  //Tank: {columnsLookup: () => []},
  Customer: {ColumnsLookup: CustomerColumns,
              Actions: {
                Edit: CustomerEdit ,
              },
             },
  Fill: {ColumnsLookup: FillColumns}
}

const Table = ({table, resource, color}) => {
  const editObject = (obj) => {
    setTargetObject(obj)
    setEdit(true)
  }
  const DefaultColumns = useMemo(() => () => [],[])
  const {
    ColumnsLookup = DefaultColumns,
    GlobalFilterHeader = () => null,
    GlobalFilter = () => null,
    Actions = {} } = useMemo(() => (TableMap[table] || {}), [table])

  const columns = useMemo(() => ColumnsLookup({editObject}), [ColumnsLookup]);
  const rows = useSelector((state) => state[resource]?.models)
  const data = useMemo(() => rows.map(p => p.attributes), [rows])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data, globalFilter: useMemo(() => GlobalFilter, [GlobalFilter])},
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )
  const [targetObject , setTargetObject] = useState()
  const [edit, setEdit] = useState(false);
  const addNew = () => {
    setTargetObject(null)
    setEdit(true)
  }
  useEffect(() =>  (document.body.style.overflow = edit ? "hidden" : "unset"), [edit])
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg md:rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t block w-full overflow-x-auto">
          <div className="flex flex-wrap items-center justify-around">
            <GlobalFilterHeader
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
    {Actions?.Edit ? 
            <div className="px-4 flex text-right">
              <button
                className="flex-grow flex-1 text-right py-2 px-2 bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 fas fa-plus text-lg"
                type="button"
                onClick={addNew}
              />
            </div>
      : <></>
    }
        </div>
          <table className="items-center w-full bg-transparent border-collapse" {...getTableProps()}>
            <thead>
                {
                      headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr className="divide-x" {...headerGroup.getHeaderGroupProps()}>
                          {// Loop over the headers in each row
                          headerGroup.headers.map(column => (
                            // Apply the header cell props
                            <TableHead color="light" {...column.getHeaderProps()} className={column.headerClassName} >
                              <span className="flex-grow flex group items-center justify-between" {...column.getHeaderProps([column.getSortByToggleProps()])}>
                                {column.render('Header')}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? <SortDownIcon className="w-4 h-4 text-gray-600" />
                                      : <SortUpIcon className="w-4 h-4 text-gray-600" />
                                    : ( column.canSort ? 
                                      <SortIcon className="w-4 h-4 text-gray-600 opacity-0 group-hover:opacity-100" /> : ""
                                    )}
                                </span>
                              </span>
                            </TableHead>
                          ))}
                                  </tr>
                      ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row, i) => 
                  prepareRow(row) || (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td colSpan={cell.column.colSpan||1}
                              {...cell.getCellProps()}
                              className={`${cell.column.className||""}`}
                              role="cell"
                            >
                              {cell.column.Cell.name === "defaultRenderer"
                                ? <div className="text-sm text-gray-500">{cell.render('Cell')}</div>
                                : cell.render('Cell')
                              }
                            </td>
                          )
                        })}
                      </tr>
                    )
                  )}
            </tbody>
          </table>
        </div>
    <hr />
    <div className="px-3 flex items-center justify-between">
          <div className="flex-1 py-2 flex justify-between sm:hidden">
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex gap-x-2 items-baseline">
              <span className="text-sm text-gray-700">
                Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
              </span>
              <label>
                <span className="sr-only">Items Per Page</span>
                <select
                  className="my-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={state.pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[5, 10, 20].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <PageButton
                  className="rounded-l-md"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </PageButton>
                <PageButton
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </PageButton>
                <PageButton
                  onClick={() => nextPage()}
                  disabled={!canNextPage
                  }>
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </PageButton>
                <PageButton
                  className="rounded-r-md"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </PageButton>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {edit ? (<Actions.Edit object={targetObject} hideAction={() => setEdit(false)}/>) : null}
    </>
  );
}

Table.defaultProps = {
  color: "light",
};

Table.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

export default Table
